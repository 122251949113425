import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"

import Find from "../components/section/FindHome"

// Our Services Section
import shake from "../images/shakehands.png"
import shake2 from "../images/shake2.png"
import girl from "../images/contract-hire.jpg"
import writing from "../images/writing-book.png"
import locum from "../images/locum-staffing.jpeg"
import Layout from "../components/layout"
import WebriqForm from "../components/form/form"
import marked from "marked"

const TechnologyPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const posts = data.allSanityPost.nodes

  // useEffect(() => {
  //   const script = document.createElement("script")
  //   script.src = "https://static.elfsight.com/platform/platform.js"
  //   script.async = true
  //   script.defer = true
  //   document.head.appendChild(script)

  //   return () => {
  //     // Cleanup: remove the script when the component is unmounted
  //     document.head.removeChild(script)
  //   }
  // }, [])

  useEffect(() => {
    const initializeWidget = () => {
      if (window.elfsightAPI && typeof window.elfsightAPI.init === "function") {
        window.elfsightAPI.init()
      } else {
        setTimeout(initializeWidget, 100)
      }
    }

    const script = document.createElement("script")
    script.src = "https://static.elfsight.com/platform/platform.js"
    script.defer = true
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout location={location} title={siteTitle} page="new-home">
      <SEO title={siteTitle} description={siteDescription} />
      <div className="video-hero">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <h1 className="hero-title">Elevating Your Search</h1>
              <p className="hero-tagline">
                IT & Radiation Oncology Staffing, nationwide.
              </p>
            </Col>
          </Row>
        </Container>
        <video muted loop autoPlay poster="/assets/hero-bg.jpeg">
          <source
            src="https://res.cloudinary.com/dwzjvi83z/video/upload/v1615007527/video_nd6hyt_z47zrg.mp4"
            type="video/mp4"
          />
          <track src="" kind="captions" srclang="en" label="English"></track>
          Your browser does not support the video tag.
        </video>
      </div>
      <div id="advertise">
        <Container>
          <Find />
        </Container>
      </div>
      <div className="home-specialties">
        <Container>
          <div className="section-content">
            <h2 className="new-h2 text-center">Our Services</h2>
            <Row className="justify-content-center">
              {services?.map(item => (
                <Col sm={6} md={6}>
                  <div className="specialty-card">
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${item?.image})` }}
                    ></div>
                    {(item?.title || item?.desc) && (
                      <div className="card-body">
                        {item?.title && (
                          <h3 className="card-title">{item?.title}</h3>
                        )}
                        {item?.desc && <p>{item.desc}</p>}
                      </div>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
            <div className="relative text-center">
              <Link className="common-btn block" to="/contact-us">
                Get Started
              </Link>
            </div>
          </div>
        </Container>
      </div>

      <div className="google-review home-review">
        <Container>
          <div className="section-content">
            <h2 className="new-h2 text-center mb-5">Testimonials</h2>
            <div className="overlay" />
            {/* <div
              className="elfsight-app-af790131-d08d-49db-823a-95924c9ee439"
              data-elfsight-app-lazy
            ></div> */}
            <div
              className="elfsight-app-24dd4562-4da4-42c1-929b-4b3d317e74b4"
              data-elfsight-app-lazy
              // data-embed-id="25369363"
            ></div>
          </div>
        </Container>
        <div className="overlay" />
      </div>

      <div id="news" className="py-100 blog-section">
        <Container className="latestNews">
          <Row className="mt-5 justify-content-center">
            <Col lg={3} md={12} className="mb-5">
              <h2 className="position-relative mb-4">Featured Posts</h2>

              <div className="mb-5">
                {" "}
                <Link to="/news" className="blue-link ">
                  from our blog
                </Link>
              </div>

              <h6>
                Sign-up to be notified <br />
                of new posts?
              </h6>

              <WebriqForm
                name="Subscribe Blog"
                className="webriq-form"
                form_id="7050eedd-8d8f-4ed8-b5de-f00c71644cc8"
              >
                <div class="form-group">
                  <label className="d-none" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Enter Your Email Here..."
                    name="email"
                    id="email"
                    className="form-control"
                  />
                </div>
                <div className="form-group mt-3">
                  <div className="webriq-recaptcha" />
                </div>
                <button className="common-btn border-none" type="submit">
                  Send
                </button>
              </WebriqForm>
            </Col>
            <Col lg={9} md={12}>
              <Row>
                {posts.map((post, index) => (
                  <Col md={6} className="mb-5" key={index}>
                    <Link to={`/${post.slug?.current}`}>
                      <div className="blog-item bg-light">
                        <div
                          className="bg-blog position-relative"
                          style={{
                            backgroundImage: `url(${post.mainImage?.asset?.fluid?.src})`,
                          }}
                        >
                          <div className="blog-date">
                            <p>
                              <i
                                className="fa fa-calendar-o"
                                aria-hidden="true"
                              ></i>{" "}
                              {post.publishedAt}
                            </p>
                          </div>
                        </div>

                        <div className="blog-body">
                          <h3 className="mb-4">{post.title}</h3>
                          <div
                            className="blog-excerpt"
                            dangerouslySetInnerHTML={{
                              __html: marked(post.excerpt),
                            }}
                          />
                        </div>

                        <div className="blog-foot">
                          <Row>
                            <Col md={7}>
                              {post.authors.length !== 0 && (
                                <p>
                                  <i
                                    className="fa fa-user"
                                    ariaHidden="true"
                                    style={{ color: "#00C1C8" }}
                                  ></i>{" "}
                                  {post.authors[0].person.name}
                                </p>
                              )}
                            </Col>
                            <Col md={5} className="text-right">
                              <p>
                                <Link
                                  to={`/${post?.slug?.current}`}
                                  style={{ color: "#00C1C8" }}
                                >
                                  <small>
                                    Read More{" "}
                                    <i
                                      className="fa fa-arrow-right"
                                      ariaHidden="true"
                                    ></i>
                                  </small>
                                </Link>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="call-us-cta">
        <Container>
          <Row className="align-items-center">
            <Col xl={7} className="text-center text-xl-left">
              <h2 className="new-h2 cta-title text-white">
                Give us a call today to find out how{" "}
                <span style={{ color: "#0088A0" }}>PeerSource</span> can help
                YOU.
              </h2>
              <p
                style={{ marginTop: "30px" }}
                className="text-white lead font-italic"
              >
                We work across the entire US and staff in all 50 states.
              </p>
            </Col>
            <Col xl={5} className="text-center text-xl-right my-5">
              <a
                href="tel:303-867-1111"
                target="_blank"
                rel="noreferrer"
                className="new-btn huge"
                style={{ display: "inline-flex", alignItems: "center" }}
              >
                <i className="fa fa-phone lead" />
                <span className="ml-2">303-867-1111</span>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default TechnologyPage

const services = [
  {
    title: "Locum Staffing",
    image: locum,
    desc:
      "Fill your short- and long-term locum tenens contracts with our network of expert clinical professionals.",
  },
  {
    title: "Contract",
    image: writing,
    desc:
      "From short-term projects to multi-year programs, we find you the right candidate for the job.",
  },
  {
    title: "Contract-to-Hire",
    image: girl,
    desc:
      "Evaluate a candidate on the job before committing to a full-time employment offer and avoid the risk of a bad hire.",
  },
  {
    title: "Direct Hire",
    image: shake2,
    desc:
      "Find the best fit for your open position. Our rigorous qualification process ensures you get the right match.",
  },
  // {
  //   title: "Payroll Services",
  //   image: shake,
  //   desc:
  //     "PeerSource offers comprehensive payroll services in partnership with our sister company OnePoint Employer Solutions.",
  // },
]

export const technologyPageQuery = graphql`
  query technologyPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }

    allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 2) {
      nodes {
        id
        title
        publishedAt(formatString: "MMM-DD-YYYY")
        mainImage {
          asset {
            fluid {
              src
            }
          }
          alt
        }
        excerpt
        slug {
          current
        }
        authors {
          person {
            name
          }
        }
      }
    }
  }
`
